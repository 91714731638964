import gql from 'graphql-tag';

export default gql`
  query getApprovedAgentsByNetwork($networkID: String!) {
    getApprovedAgentsByNetwork(networkID: $networkID) {
      agentID
      firstName
      lastName
    }
  }
`;
