import * as yup from 'yup';

export const autoMatchDepositSchema = yup.object().shape({
  enabled: yup.boolean().nullable(),
  agentID: yup
    .string()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(3, 'Agent ID must be at least 3 characters.')
          .required('Agent ID is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  groups: yup
    .array()
    .of(
      yup.object().shape({
        name: yup
          .string()
          .min(3, 'Name must be at least 3 characters')
          .required('Name is required'),
        percentage: yup
          .number()
          .moreThan(0, 'Percentage must be greater than 0')
          .max(100, 'Percentage must be less than 100')
          .required('Percentage is required'),
        agents: yup.array().min(1, 'At least one agent is required'),
      })
    )
    .nullable(),
  minTransferAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Minimum Transfer Amount must be greater than 0.')
          .required('Minimum Transfer Amount is required.')
          .test(
            'min-less-than-max',
            'Min Transfer Amount must be lower than Max Transfer Amount.',
            function (minTransferAmount) {
              const { maxTransferAmount } = this.parent;
              if (minTransferAmount == null || maxTransferAmount == null)
                return true; // Skip if one is null
              return minTransferAmount < maxTransferAmount;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  maxTransferAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Maximum Transfer Amount must be greater than 0.')
          .required('Maximum Transfer Amount is required.')
          .test(
            'min-less-than-max',
            'Max Transfer Amount must be greater than Min Transfer Amount.',
            function (maxTransferAmount) {
              const { minTransferAmount } = this.parent;
              if (minTransferAmount == null || maxTransferAmount == null)
                return true; // Skip if one is null
              return maxTransferAmount > minTransferAmount;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  minDepositAmount: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .moreThan(0, 'Minimum Deposit Amount must be greater than 0.')
          .required('Minimum Deposit Amount is required.'),
      otherwise: (schema) => schema.nullable(),
    }),
  startDay: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(0, 'Invalid start day.')
          .max(6, 'Invalid start day.')
          .required('Start day is required.')
          .test(
            'start-not-equal-end',
            'Start day and End day cannot be the same.',
            function (startDay) {
              const { endDay } = this.parent;
              return endDay !== startDay;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
  endDay: yup
    .number()
    .nullable()
    .when('enabled', {
      is: true,
      then: (schema) =>
        schema
          .min(0, 'Invalid end day.')
          .max(6, 'Invalid end day.')
          .required('End day is required.')
          .test(
            'start-not-equal-end',
            'Start day and End day cannot be the same.',
            function (endDay) {
              const { startDay } = this.parent;
              return startDay !== endDay;
            }
          ),
      otherwise: (schema) => schema.nullable(),
    }),
});

export const roleOptions = [
  { value: 'manager', label: 'Manager' },
  { value: 'user', label: 'Agent' },
  { value: 'admin', label: 'Admin' },
  { value: 'super_admin', label: 'Super Admin' },
];
