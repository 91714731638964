import React, { useState, useEffect, useContext, useMemo } from 'react';
import { Nav, NavItem, Badge } from 'reactstrap';
import classnames from 'classnames';
import {
  FaAddressBook,
  FaDollarSign,
  FaCog,
  FaQuestion,
  FaFileCsv,
  FaHome,
  FaPersonBooth,
  FaHandHoldingUsd,
  FaServer,
  FaPhoneSlash,
  FaCartPlus,
  FaChartBar,
} from 'react-icons/fa';
import { NotificationIcon } from '@/components';
import { useLocation, useHistory } from 'react-router-dom';
import { Vars } from '@/utils';
import { NotificationContext } from '@/context';
import { useAdmin, useNetwork } from '@/hooks';
import { Network } from '@sonic-web-dev/types';

interface LeftNavProps {
  collapsed: boolean;
}

type NavItemType = {
  label: string;
  icon: any;
  path: string | ((network: Network) => string);
  disabled?: boolean;
  roles?: string[];
  tiers?: boolean;
};
const navItems: NavItemType[] = [
  {
    label: 'Dashboard',
    icon: <FaHome />,
    path: '/',
    roles: ['super_internal_admin', 'internal_admin', 'admin'],
  },
  {
    label: 'Agents',
    icon: <FaAddressBook />,
    path: '/agents',
    roles: ['super_internal_admin', 'internal_admin', 'admin', 'support'],
  },
  {
    label: 'Leads',
    icon: <FaPersonBooth />,
    path: '/leads',
    roles: ['super_internal_admin', 'internal_admin', 'admin', 'support'],
  },
  {
    label: 'Refunds',
    icon: <FaPhoneSlash />,
    path: '/refunds',
    roles: ['super_internal_admin', 'internal_admin', 'admin'],
  },
  {
    label: 'Price Tiers',
    icon: <FaDollarSign />,
    path: '/price-tiers',
    roles: ['super_internal_admin', 'internal_admin', 'admin'],
    tiers: true,
  },
  {
    label: 'Lead Sources',
    icon: <FaHandHoldingUsd />,
    path: '/lead-sources',
    roles: ['super_internal_admin', 'internal_admin', 'admin'],
    tiers: true,
  },
  {
    label: 'Vendors',
    icon: <FaCartPlus />,
    path: '/vendors',
    roles: ['super_internal_admin', 'internal_admin'],
  },
  {
    label: 'Network Settings',
    icon: <FaCog />,
    path: '/settings',
    roles: ['super_internal_admin', 'internal_admin'],
  },
  {
    label: 'Reports',
    icon: <FaFileCsv />,
    path: '/reports',
    roles: ['super_internal_admin', 'internal_admin', 'admin'],
  },
  {
    label: 'Infastructure',
    icon: <FaServer />,
    path: '/infastructure',
    roles: ['super_internal_admin'],
  },
  {
    label: 'Analytics',
    icon: <FaChartBar />,
    path: '/analytics',
    roles: ['super_internal_admin', 'internal_admin', 'admin', 'support'],
  },
];

const getSiteInfo = () => {
  if (window.location.href.includes('leadtopia')) {
    return {
      siteTitle: 'LeadTopia',
      collapsedSiteTitle: 'LT',
    };
  }
  return window.location.href.includes('ushamarketplace')
    ? {
        siteTitle: 'USHA',
        collapsedSiteTitle: 'UH',
      }
    : {
        siteTitle: 'Lead Arena',
        collapsedSiteTitle: 'LA',
      };
};

const { siteTitle, collapsedSiteTitle } = getSiteInfo();

const LeftNav: React.FC<LeftNavProps> = ({ collapsed }) => {
  const { network, loading: loadingNetwork } = useNetwork();

  const shouldDisablePriceTiers =
    !loadingNetwork && !network?.overflowPriceTierID;
  const [minWidth, setMinWidth] = useState<number | undefined>(
    collapsed ? 76 : 260
  );
  const paddingClassName = collapsed ? 'px-3' : 'px-5';
  const location = useLocation();
  const history = useHistory();

  const { tabNotification, setTabNotification } = useContext(
    NotificationContext
  );

  const admin = useAdmin();

  useEffect(() => {
    setTabNotification({
      ...tabNotification,
      reports: !(location.pathname === '/reports') && tabNotification.reports,
    });
  }, [tabNotification.reports]);

  useEffect(() => {
    setMinWidth(undefined);
    const tm = setTimeout(() => {
      setMinWidth(collapsed ? 76 : 260);
    }, 300);

    return () => clearTimeout(tm);
  }, [collapsed]);

  return (
    <Nav
      navbar
      vertical
      style={{
        backgroundColor: '#393939',
        width: collapsed ? 76 : 260,
        minWidth,
        borderRight: '2px solid #4a4a4a',
      }}
      className="pt-3 navbar-transition"
    >
      <NavItem
        className={`h3 d-flex flex-column cursor-pointer text-center mb-4 ${paddingClassName}`}
        onClick={() => history.push('/')}
      >
        {collapsed ? (
          <span>
            {collapsedSiteTitle}
            {Vars.env === 'dev' && <div className="round-test-icon" />}
          </span>
        ) : (
          <div className="admin-header">
            <span>{siteTitle}</span>{' '}
            <span className="d-flex align-items-center">
              Admin{' '}
              {Vars.env === 'dev' && (
                <Badge
                  style={{
                    fontSize: '.9rem',
                  }}
                  color="warning"
                  title="This is the marketplace test version."
                  className="ml-2"
                >
                  Test
                </Badge>
              )}
            </span>
          </div>
        )}
      </NavItem>
      {navItems
        .filter((n) => {
          if (
            n.path === '/refunds' &&
            !network?.refundsVisible &&
            admin.role === 'admin'
          )
            return false;
          return !n.roles || n.roles.includes(admin.role);
        })
        .map((item) => {
          if (typeof item.path === 'function') {
            item.path = item.path(network!);
          }
          const active =
            item.path !== '/'
              ? location.pathname.includes(item.path)
              : location.pathname === '/';
          return (
            <NavItem
              key={item.path}
              className={classnames(
                'py-3 d-flex align-items-center admin-nav-item cursor-pointer mb-0',
                {
                  'px-3 h5': !collapsed,
                  'px-1 justify-content-center h3': collapsed,
                  'bg-dark admin-nav-item-active': active,
                  'text-muted':
                    item.disabled || (item.tiers && shouldDisablePriceTiers),
                }
              )}
              onClick={() => {
                setTabNotification({
                  ...tabNotification,
                  [item.label.toLowerCase()]: false,
                });
                if (typeof item.path === 'function') {
                  item.path = item.path(network!);
                }
                return (
                  !item.disabled &&
                  (!item.tiers || !shouldDisablePriceTiers) &&
                  history.push(item.path)
                );
              }}
            >
              <div className="d-flex align-items-center">
                {item.icon}
                {!collapsed && <span className="ml-2">{item.label}</span>}
                {tabNotification[item.label.toLowerCase()] && !active && (
                  <NotificationIcon color="danger" />
                )}
              </div>
            </NavItem>
          );
        })}
      <NavItem
        className={classnames(
          'py-3 d-flex align-items-center admin-nav-item cursor-pointer mb-0 mt-auto',
          {
            'px-3 h5': !collapsed,
            'px-1 justify-content-center h3': collapsed,
          }
        )}
        onClick={() =>
          window.open(
            'https://knowledge.ushamarketplace.com/supportcenter',
            '_blank'
          )
        }
      >
        <FaQuestion />
        {!collapsed && <span className="ml-2">Get Help</span>}
      </NavItem>
    </Nav>
  );
};

export default LeftNav;
