import { agentsByNetworkQuery } from '@/graphql/queries';
import { useNetwork } from '@/hooks';
import { useQuery } from '@apollo/react-hooks';
import { Field } from '@availity/form';
import { SelectField } from '@availity/select';
import { useFormikContext } from 'formik';
import React from 'react';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';

const MatchByGroups: React.FC = () => {
  const {
    values,
    setFieldValue,
    errors,
    touched,
    setFieldTouched,
  } = useFormikContext();
  const { network } = useNetwork();
  const groups = values?.autoMatchDeposit?.groups || [];

  // Fetch all approved agents for the network
  const { data: agentsData } = useQuery(agentsByNetworkQuery, {
    variables: { networkID: network?.networkID },
  });

  // Get all selected agents across all groups
  const selectedAgentsInOtherGroups = (groupIndex: number) => {
    return groups.reduce((acc: string[], group, idx) => {
      // Skip the current group and groups without agents
      if (idx !== groupIndex && group.agents && group.agents.length > 0) {
        // Extract just the agentIDs from the selected agents
        return [...acc, ...group.agents.map((agent) => agent.value || agent)];
      }
      return acc;
    }, []);
  };

  const getAvailableAgentOptions = (groupIndex: number) => {
    const selectedAgents = selectedAgentsInOtherGroups(groupIndex);
    const allAgents =
      agentsData?.getApprovedAgentsByNetwork?.map((agent) => ({
        value: agent.agentID,
        label: `${agent.firstName} ${agent.lastName}`,
        firstName: agent.firstName,
        lastName: agent.lastName,
      })) || [];

    return allAgents.filter((agent) => !selectedAgents.includes(agent.value));
  };

  const addGroup = () => {
    setFieldValue('autoMatchDeposit.groups', [
      ...groups,
      { name: '', agents: [], percentage: '' },
    ]);
  };

  const removeGroup = (index) => {
    const updatedGroups = [...groups];
    updatedGroups.splice(index, 1);
    setFieldValue('autoMatchDeposit.groups', updatedGroups);
  };

  const updateGroupName = (index, name) => {
    const updatedGroups = [...groups];
    updatedGroups[index].name = name;
    setFieldValue('autoMatchDeposit.groups', updatedGroups);
  };

  return (
    <Col>
      <div>
        {groups.map((group, index) => (
          <Row key={index} className="mb-4">
            <Col xs={12} className="mb-2">
              <h5>Group #{index + 1}</h5>
            </Col>
            <Col xs={5}>
              <Field
                type="text"
                name={`autoMatchDeposit.groups[${index}].name`}
                className="form-control mb-2"
                placeholder="Group Name"
                value={group.name || ''}
                onChange={(e) => updateGroupName(index, e.target.value)}
                onBlur={() =>
                  setFieldTouched(
                    `autoMatchDeposit.groups[${index}].name`,
                    true
                  )
                }
              />
            </Col>
            <Col xs={4}>
              <InputGroup size="md" style={{ minWidth: '100px' }}>
                <InputGroupAddon addonType="prepend">%</InputGroupAddon>
                <Input
                  type="number"
                  name={`autoMatchDeposit.groups[${index}].percentage`}
                  className="form-control"
                  placeholder="Percentage"
                  value={group.percentage || ''}
                  onChange={(e) => {
                    const updatedGroups = [...groups];
                    updatedGroups[index].percentage = Number(e.target.value);
                    setFieldValue('autoMatchDeposit.groups', updatedGroups);
                  }}
                  onBlur={() =>
                    setFieldTouched(
                      `autoMatchDeposit.groups[${index}].percentage`,
                      true
                    )
                  }
                  min="1"
                  max="100"
                />
              </InputGroup>
              {errors?.autoMatchDeposit?.groups?.[index]?.percentage &&
                touched?.autoMatchDeposit?.groups?.[index]?.percentage && (
                  <small className="w-100 text-danger">
                    {errors.autoMatchDeposit.groups[index].percentage}
                  </small>
                )}
            </Col>
            <Col xs={9}>
              <SelectField
                name={`autoMatchDeposit.groups[${index}].agents`}
                options={getAvailableAgentOptions(index)}
                isMulti
                value={group.agents || []}
                onChange={(selectedAgents) => {
                  const updatedGroups = [...groups];
                  updatedGroups[index].agents = selectedAgents || [];
                  setFieldValue('autoMatchDeposit.groups', updatedGroups);
                }}
                placeholder="Select agents..."
              />
            </Col>
            <Col xs={3}>
              <Button
                type="button"
                color="secondary"
                onClick={() => removeGroup(index)}
              >
                Remove Group
              </Button>
            </Col>
          </Row>
        ))}
        <Row>
          <Col style={{ paddingBottom: '20px' }}>
            <Button type="button" color="primary" onClick={addGroup}>
              Add New Group
            </Button>
          </Col>
        </Row>
      </div>
    </Col>
  );
};

export default MatchByGroups;
